.changeDropdown {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: 0 20px;
  }
  .dropdown, .dropup {
    position: relative;
  }
  button.dropbtn {
    background: #f1f1f1;
    min-width: 100px !important;
    color: #000;
    padding: 0.5em 1em;
    border-radius: 5px;
  }
  .dropbtn {
    color: #333;
    padding: 8px 25px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    text-align: left;
  }
  .dropdown-content {
    position: absolute;
    background-color: #f1f1f1;
    min-width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    top: 35px;
    border-radius: 0px 0px 5px 5px;
  }
  .dropdown-content div {
    color: black;
    width: 100%;
    padding: 12px 16px;
    font-size: 14px !important;
    text-decoration: none;
    display: block;
    cursor: pointer;
  }


  .logoCss {
    width: 40px;
    height: 40px;
  }

  @media screen and (min-width: 768px) {
    .logoCss {
      width: 60px;
      height: 60px;
    }
  }
  