@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap');
@import 'bootstrap/dist/css/bootstrap.min.css';
/* @import './node_modules/pikaday/css/pikaday.css'; */

@font-face {
    font-family: 'outfitMedium';
    src: url('../src/template/fonts/Outfit-Medium.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
@font-face {
    font-family: 'outfitRegular';
    src: url('../src/template/fonts/Outfit-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'outfitLight';
    src: url('../src/template/fonts/Outfit-Light.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  body {
    font-family: 'outfitLight', sans-serif;
  }
  h1{
    font-weight: 900;
  }
  h1,h2,h3,h4,h5,h6{
    font-family: 'outfitMedium', sans-serif;
  }

:root {
    --primary:#00A7E7;
    --white:#fff;
    --gray:lightgray;
    --black:#000;
    --secondary: #26539F;
}

body::before {
    content: "";
    /*background-image: url(../src/template/images/background.jpg);*/
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.1; /* Adjust the value (0.0 to 1.0) for opacity */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
}
body, html {
    margin:0;
    padding:0;
    box-sizing: border-box;
    /*font-family: 'Inter', sans-serif;*/
}

.flex-center {
    display: flex;
    justify-content:center;
    align-items:center;
}

.flex-align-center {
    display:flex;
    align-items:center;
}

.flex-justify-center {
    display: flex;
    justify-content: center;
}

.style-none {
    list-style-type: none;
    text-decoration: none;
}

.text-mute {
    color:gray;
}

.text-center {
    text-align: center;
}

.primay-color {
    color:var(--primary);
}

.w-50 {
    width:50%;
}

.py-10 {
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.d-flex {
    display:flex;
}
.top_security {
    display: flex;
    justify-content: flex-end;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 25px;
    padding: 0px 20px;
  }
  .top_sec_p {
    text-align: center;
    background-color: var(--secondary);
    padding: 10px 40px;
    width: 40%;
    color: #fff;
  }
  @media (max-width: 767px){
    .top_sec_p {
      padding: 10px 20px;
      width: 100%;
    }
  }
  .secondary{
    color: var(--secondary);
  }
  .primary{
    color: var(--primary);
  }
  .mt_50{
    margin-top: 50px;
  }
  
.form-control,.form-select{
    border-radius: 0px;
}

.tooltip {
  position: relative;
  display: inline-block;
  --bs-tooltip-zindex: 0;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 400px;
  background-color: black;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 5px;
  font-size: 12px;
  
  /* Position the tooltip */
  position: absolute;
  /* z-index: 1; */
  top: -5px;
  left: 105%;
}

.tooltip .tooltiptextPEP {
  visibility: hidden;
  width: 1000px;
  background-color: black;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 5px;
  font-size: 12px;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 105%;
}

.tooltip .tooltiptextRight {
  visibility: hidden;
  width: 400px;
  background-color: black;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 5px;
  font-size: 12px;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -5px;
  right: 105%;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip:hover .tooltiptextPEP {
  visibility: visible;
}

.tooltip:hover .tooltiptextRight {
  visibility: visible;
}

.headingBlock {
  max-width: 1200px;
  padding: 0px 20px 40px 20px;
  margin: 0 auto;
}

input::-ms-reveal {
  display: none;
}

.linkList {
  display: flex;
  flex-wrap: wrap;
  column-gap: 6px;
}

.optionalInput {
  width: 250.66px;
}

@media (max-width: 480px) {
  .optionalInput {
    width: 100%;
  }

  .tooltip {
    position: relative;
    display: inline-block;
    --bs-tooltip-zindex: 1;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 250px;
    background-color: black;
    color: #fff;
    text-align: left;
    border-radius: 6px;
    padding: 5px;
    font-size: 12px;
    
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 28px;
    right: 0%;
    left: -1750%
  }

  .tooltip .tooltiptextPEP {
    visibility: hidden;
    width: 280px;
    background-color: black;
    color: #fff;
    text-align: left;
    border-radius: 6px;
    padding: 5px;
    font-size: 12px;
    
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 40px;
    right: 0%;
    left: -1650%;
  }
}

.continue-application {
  --color: #fff;
  --background: #404660;
  --background-hover: #3A4059;
  --background-left: #2B3044;
  --folder: #F3E9CB;
  --folder-inner: #BEB393;
  --paper: #FFFFFF;
  --paper-lines: #BBC1E1;
  --paper-behind: #E1E6F9;
  --pencil-cap: #fff;
  --pencil-top: #275EFE;
  --pencil-middle: #fff;
  --pencil-bottom: #5C86FF;
  --shadow: rgba(13, 15, 25, .2);
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  width: 250px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  padding: 17px 29px 17px 55px;
  transition: background 0.3s;
  color: var(--color);
  background: rgb(0,167,231);
}

.continue-application > div {
  top: 0;
  left: 0;
  bottom: 0;
  width: 53px;
  position: absolute;
  overflow: hidden;
  border-radius: 5px 0 0 5px;
  background: var(--background-left);
}

.continue-application > div .folder {
  width: 23px;
  height: 27px;
  position: absolute;
  left: 15px;
  top: 13px;
}

.continue-application > div .folder .top {
  left: 0;
  top: 0;
  z-index: 2;
  position: absolute;
  transform: translateX(var(--fx, 0));
  transition: transform 0.4s ease var(--fd, 0.3s);
}

.continue-application > div .folder .top svg {
  width: 24px;
  height: 27px;
  display: block;
  fill: var(--folder);
  transform-origin: 0 50%;
  transition: transform 0.3s ease var(--fds, 0.45s);
  transform: perspective(120px) rotateY(var(--fr, 0deg));
}

.continue-application > div .folder:before, .continue-application > div .folder:after,
.continue-application > div .folder .paper {
  content: "";
  position: absolute;
  left: var(--l, 0);
  top: var(--t, 0);
  width: var(--w, 100%);
  height: var(--h, 100%);
  border-radius: 1px;
  background: var(--b, var(--folder-inner));
}

.continue-application > div .folder:before {
  box-shadow: 0 1.5px 3px var(--shadow), 0 2.5px 5px var(--shadow), 0 3.5px 7px var(--shadow);
  transform: translateX(var(--fx, 0));
  transition: transform 0.4s ease var(--fd, 0.3s);
}

.continue-application > div .folder:after,
.continue-application > div .folder .paper {
  --l: 1px;
  --t: 1px;
  --w: 21px;
  --h: 25px;
  --b: var(--paper-behind);
}

.continue-application > div .folder:after {
  transform: translate(var(--pbx, 0), var(--pby, 0));
  transition: transform 0.4s ease var(--pbd, 0s);
}

.continue-application > div .folder .paper {
  z-index: 1;
  --b: var(--paper);
}

.continue-application > div .folder .paper:before, .continue-application > div .folder .paper:after {
  content: "";
  width: var(--wp, 14px);
  height: 2px;
  border-radius: 1px;
  transform: scaleY(0.5);
  left: 3px;
  top: var(--tp, 3px);
  position: absolute;
  background: var(--paper-lines);
  box-shadow: 0 12px 0 0 var(--paper-lines), 0 24px 0 0 var(--paper-lines);
}

.continue-application > div .folder .paper:after {
  --tp: 6px;
  --wp: 10px;
}

.continue-application > div .pencil {
  height: 2px;
  width: 3px;
  border-radius: 1px 1px 0 0;
  top: 8px;
  left: 105%;
  position: absolute;
  z-index: 3;
  transform-origin: 50% 19px;
  background: var(--pencil-cap);
  transform: translateX(var(--pex, 0)) rotate(35deg);
  transition: transform 0.4s ease var(--pbd, 0s);
}

.continue-application > div .pencil:before, .continue-application > div .pencil:after {
  content: "";
  position: absolute;
  display: block;
  background: var(--b, linear-gradient(var(--pencil-top) 55%, var(--pencil-middle) 55.1%, var(--pencil-middle) 60%, var(--pencil-bottom) 60.1%));
  width: var(--w, 5px);
  height: var(--h, 20px);
  border-radius: var(--br, 2px 2px 0 0);
  top: var(--t, 2px);
  left: var(--l, -1px);
}

.continue-application > div .pencil:before {
  -webkit-clip-path: polygon(0 5%, 5px 5%, 5px 17px, 50% 20px, 0 17px);
  clip-path: polygon(0 5%, 5px 5%, 5px 17px, 50% 20px, 0 17px);
}

.continue-application > div .pencil:after {
  --b: none;
  --w: 3px;
  --h: 6px;
  --br: 0 2px 1px 0;
  --t: 3px;
  --l: 3px;
  border-top: 1px solid var(--pencil-top);
  border-right: 1px solid var(--pencil-top);
}

.continue-application:before, .continue-application:after {
  content: "\003E";
  position: absolute;
  width: 10px;
  height: 2px;
  border-radius: 1px;
  top: 18px;
  right: 16px;
  transition: transform 0.3s;
}

.continue-application:after {
  --r: 45deg;
}

.continue-application:hover {
  --cx: 2px;
  --bg: var(--background-hover);
  --fx: -40px;
  --fr: -60deg;
  --fd: .15s;
  --fds: 0s;
  --pbx: 3px;
  --pby: -3px;
  --pbd: .15s;
  --pex: -24px;
}


.continue-application-per {
  --color: #fff;
  --background: #404660;
  --background-hover: #3A4059;
  --background-left: #2B3044;
  --folder: #F3E9CB;
  --folder-inner: #BEB393;
  --paper: #FFFFFF;
  --paper-lines: #BBC1E1;
  --paper-behind: #E1E6F9;
  --pencil-cap: #fff;
  --pencil-top: #275EFE;
  --pencil-middle: #fff;
  --pencil-bottom: #5C86FF;
  --shadow: rgba(13, 15, 25, .2);
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  width: 250px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  padding: 17px 29px 17px 6px;
  transition: background 0.3s;
  color: var(--color);
  background: rgb(0,167,231);
}

.continue-application-per > div {
  top: 0;
  right: 0;
  bottom: 0;
  width: 53px;
  position: absolute;
  overflow: hidden;
  border-radius: 5px 0 0 5px;
  background: var(--background-left);
}

.continue-application-per > div .folder {
  width: 23px;
  height: 27px;
  position: absolute;
  left: 15px;
  top: 13px;
}

.continue-application-per > div .folder .top {
  left: 0;
  top: 0;
  z-index: 2;
  position: absolute;
  transform: translateX(var(--fx, 0));
  transition: transform 0.4s ease var(--fd, 0.3s);
}

.continue-application-per > div .folder .top svg {
  width: 24px;
  height: 27px;
  display: block;
  fill: var(--folder);
  transform-origin: 0 50%;
  transition: transform 0.3s ease var(--fds, 0.45s);
  transform: perspective(120px) rotateY(var(--fr, 0deg));
}

.continue-application-per > div .folder:before, .continue-application-per > div .folder:after,
.continue-application-per > div .folder .paper {
  content: "";
  position: absolute;
  left: var(--l, 0);
  top: var(--t, 0);
  width: var(--w, 100%);
  height: var(--h, 100%);
  border-radius: 1px;
  background: var(--b, var(--folder-inner));
}

.continue-application-per > div .folder:before {
  box-shadow: 0 1.5px 3px var(--shadow), 0 2.5px 5px var(--shadow), 0 3.5px 7px var(--shadow);
  transform: translateX(var(--fx, 0));
  transition: transform 0.4s ease var(--fd, 0.3s);
}

.continue-application-per > div .folder:after,
.continue-application-per > div .folder .paper {
  --l: 1px;
  --t: 1px;
  --w: 21px;
  --h: 25px;
  --b: var(--paper-behind);
}

.continue-application-per > div .folder:after {
  transform: translate(var(--pbx, 0), var(--pby, 0));
  transition: transform 0.4s ease var(--pbd, 0s);
}

.continue-application-per > div .folder .paper {
  z-index: 1;
  --b: var(--paper);
}

.continue-application-per > div .folder .paper:before, .continue-application-per > div .folder .paper:after {
  content: "";
  width: var(--wp, 14px);
  height: 2px;
  border-radius: 1px;
  transform: scaleY(0.5);
  left: 3px;
  top: var(--tp, 3px);
  position: absolute;
  background: var(--paper-lines);
  box-shadow: 0 12px 0 0 var(--paper-lines), 0 24px 0 0 var(--paper-lines);
}

.continue-application-per > div .folder .paper:after {
  --tp: 6px;
  --wp: 10px;
}

.continue-application-per > div .pencil {
  height: 2px;
  width: 3px;
  border-radius: 1px 1px 0 0;
  top: 8px;
  left: 105%;
  position: absolute;
  z-index: 3;
  transform-origin: 50% 19px;
  background: var(--pencil-cap);
  transform: translateX(var(--pex, 0)) rotate(35deg);
  transition: transform 0.4s ease var(--pbd, 0s);
}

.continue-application-per > div .pencil:before, .continue-application-per > div .pencil:after {
  content: "";
  position: absolute;
  display: block;
  background: var(--b, linear-gradient(var(--pencil-top) 55%, var(--pencil-middle) 55.1%, var(--pencil-middle) 60%, var(--pencil-bottom) 60.1%));
  width: var(--w, 5px);
  height: var(--h, 20px);
  border-radius: var(--br, 2px 2px 0 0);
  top: var(--t, 2px);
  left: var(--l, -1px);
}

.continue-application-per > div .pencil:before {
  -webkit-clip-path: polygon(0 5%, 5px 5%, 5px 17px, 50% 20px, 0 17px);
  clip-path: polygon(0 5%, 5px 5%, 5px 17px, 50% 20px, 0 17px);
}

.continue-application-per > div .pencil:after {
  --b: none;
  --w: 3px;
  --h: 6px;
  --br: 0 2px 1px 0;
  --t: 3px;
  --l: 3px;
  border-top: 1px solid var(--pencil-top);
  border-right: 1px solid var(--pencil-top);
}

.continue-application-per:before, .continue-application-per:after {
  content: "\003C";
  position: absolute;
  width: 10px;
  height: 2px;
  border-radius: 1px;
  top: 18px;
  left: 16px;
  transition: transform 0.3s;
}

.continue-application-per:after {
  --r: 45deg;
}

.continue-application-per:hover {
  --cx: 2px;
  --bg: var(--background-hover);
  --fx: -40px;
  --fr: -60deg;
  --fd: .15s;
  --fds: 0s;
  --pbx: 3px;
  --pby: -3px;
  --pbd: .15s;
  --pex: -24px;
}

.mobileView {
  display: none;
} 

.DesktopView {
  display: block;
}

@media (max-width: 480px) {
  .mobileView {
    display: block;
  }
  
  .DesktopView {
    display: none;
  }

  .borderInput {
    border: 1px solid #DEE2E6 !important;
    width: 100% !important;
    height: 37.5px;
    z-index: 9999;
  }

  .mobile-disabled {
    pointer-events: none;
    opacity: 0.5;
  }

}

.react-datepicker__input-container {
  display: inline-block;
  position: relative;
  width: 100%;
  border-color: #DEE2E6 !important;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}

.borderInput {
  border: 1px solid #DEE2E6 !important;
  width: 370px;
  height: 37.5px;
  z-index: 9999;
}

.react-datepicker__navigation--years-previous {
  top: 4px;
  position: relative;
  background-color: black;
  height: 15px;
  width: 15px;
  border-radius: 5px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.react-datepicker__navigation--years-previous::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid white;
}


.pika-select-month {
  margin: 5px;
  font-size: 14px;
}

.pika-select {
  display: inline-block;
  margin-right: 10px;
}