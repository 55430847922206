.justify{
    text-align: justify;
}
.footer{
    background-color: var(--secondary);
}
.footer div{
    max-width: 1200px;
    color: #fff;
    margin: 0 auto;
    padding: 70px 30px;
    text-align: justify;
    bottom: 0px;
    /*position: sticky;*/
}
.header{
    box-shadow: 1px 1px 1px 1px #f7f7f7;
    padding: 20px;
    position: sticky;
    z-index: 9;
    top: 0;
    background-color: #ffffff;
}

.passwordValid{
    box-shadow: 8px 8px 1px 0px var(--secondary);
    padding: 20px;
    border: 1px solid var(--secondary);
}
.valid,.invalid{
    margin-bottom: 0px;
}
.w_100{
    width: 100%;
}
.ml_0{
    margin-left: 0px !important;
}
.dropbtn{
    display: flex;
    justify-content: space-between;
}
@media (max-width: 767px){
    .row .primary{
        width: 100%;
        padding: 0px 24px;
    }
    .phoneNum{
        margin-bottom: 30px;
    }
    .col.buttonWidth {
        width: 150px;
        margin: 10px;
      }
}

.loaders, #global-loader1 {
    position: fixed;
    top: 50%; /* Center the loader vertically */
    left: 50%; /* Center the loader horizontally */
    transform: translate(-50%, -50%); /* Center the loader perfectly */
    z-index: 99;
    width: 100%;
    height: 100%; /* Make the loader cover the entire viewport */
    background: #2752a073; /* Semi-transparent background */
}

.loaders img, #global-loader1 img {
    position: absolute; /* Position the loader image absolutely */
    top: 50%; /* Center the image vertically */
    left: 50%; /* Center the image horizontally */
    width: 100px;
    transform: translate(-50%, -50%); /* Center the image perfectly */
    border-radius: 15px;
}
.valid{
    color: green;
}
.invalid{
    color: red;
}

.backgroundColor {
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 20;
    display: flex;
    align-items: center;
    justify-items: center;
    font-family: 'outfitLight', sans-serif;
}

.cardContainer {
    width: 380px;
    height: 200px;
    background-color: white;
    border-radius: 15px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.inputField {
    margin-top: 8px;
    width: 280px;
    height: 40px;
}

.selected, .arrow, .up {
  display: none;
}

.selected, .country-list {
    display: none;
}